export default ({ app, req, $cookie }, inject) => {
    if (process.server) {
        try {
            const userAgent = req.headers['user-agent']
            const isMobile = /mobile|android|iphone|ipad|phone|line/i.test(
                userAgent?.toLowerCase()
            )
            app.store.commit('themes/settings/setIsMobile', isMobile)

            if ($cookie?.get('token')) {
                app.store.commit('settings/setIsLogin', true)
            }
        } catch (error) {}
    }
}
